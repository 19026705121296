import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import './sliderSection.scss';
import { Link } from 'react-router-dom';
import image1 from '../../../assets/images/carousel/image1.jpg';
import image2 from '../../../assets/images/carousel/image2.jpg';
import image3 from '../../../assets/images/carousel/image3.jpg';
import image4 from '../../../assets/images/carousel/image4.jpg';
import PrevArrow from '../../../components/SliderArrows/PrevArrow';
import NextArrow from '../../../components/SliderArrows/NextArrow';
import Button from '../../../components/Button/Button';

function CarouselSection() {

    const content = [
        { id: 1, image: image1, text: "Lake" },
        { id: 2, image: image2, text: "Vardavar" },
        { id: 3, image: image3, text: "Geghard Monastery" },
        { id: 4, image: image4, text: "Garni" }
    ];

    const settings = {
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],

    };


    return (
        <div className="container-fluid carousel-section h-100 w-100 pt-5 px-2 d-flex align-items-center justify-content-center mt-5">
            <div className="row flex-lg-row-reverse-align-items-start align-items-center g-5 w-100 py-4">
                <div className="col col-lg-3 col-sm-12 px-2 m-0">
                    <h2 className="section-subheading text-white">featured works</h2>
                    <h1 className="section-heading display-5 fw-bold lh-1 mb-3">EXPLORE <br /> THE PROJECTS</h1>
                    <p className="description lh-base">
                        We specialize in field recording, original sound ambience,
                        and film scoring for a diverse and high - end clientele all around the world. We are early-adopter of new technologies in anticipation of industry trends.
                    </p>
                    <Link to="/collections"> <Button text="View new collections" bClassName="my-4" /> </Link>

                </div>

                <div className="col col-sm-12 col-lg-9 mt-2 px-2">

                    <Slider className="w-100 h-100" {...settings}>

                        {content.map((item) =>

                            <div key={item.id} className="card px-3 w-100 h-100 border-0 bg-transparent d-flex flex-column align-items-center">
                                <img className="card-img-top w-100 d-block" alt="Featured Works" src={item.image} />
                                <div className="card-body p-0">

                                    <p className="card-text carousel-title my-2 text-center">
                                        {item.text}
                                    </p>
                                </div>
                            </div>
                        )}

                    </Slider>

                </div>

            </div>

        </div>

    );
}

export default CarouselSection;