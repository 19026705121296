function NextArrow(props) {
    const className = props.className;
    const style = props.style;
    const onClick = props.onClick;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                right: "-9px",
                position: "absolute",
                zIndex: "1"

            }}
            onClick={onClick}
        />
    );
}

export default NextArrow;