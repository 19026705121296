import './newsSection.scss';
import products1 from '../../../assets/images/products/image1.png';
import products2 from '../../../assets/images/products/image2.jpg';
import products3 from '../../../assets/images/products/image3.jpg';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';

function NewsSection() {

    const productsContent = [
        { id: 1, title: "individual", text: "for specific orders", image: products1, href: "/individual" },
        { id: 2, title: "gallery", text: "FOLLOW  OUR  INSTAGRAM  PAGE", image: products2, href: "/gallery" },
        { id: 3, title: "shop", text: "all products here", image: products3, href: "/shop" },
        { id: 4, title: "individual", text: "for specific orders", image: products1, href: "/individual" },
        { id: 5, title: "gallery", text: "FOLLOW  OUR  INSTAGRAM  PAGE", image: products2, href: "/gallery" },
        { id: 6, title: "shop", text: "all products here", image: products3, href: "/shop" }

    ];

    return (
        <div className="container-fluid p-0 background-overlay h-100 w-100 px-0">
            <div className="container-fluid p-0 my-5 py-5 h-100 w-100 content">
                <div className="container py-5 my-5 text-center">
                    <div className="heading mb-5">
                        <h2 className="title text-center mb-3">
                            <span className="text-uppercase me-3 title1">WHAT'S</span>
                            <span className="text-uppercase title2">NEXT</span>
                        </h2>
                        <p className="text-uppercase lead text-center text-white">DISCOVER OUR RANGE OF SERVICES THAT SETS THE BEAT FOR YOUR PROJECT.</p>
                    </div>

                    <div className="row m-0 justify-content-center">
                        {productsContent.map((product, index) => {
                            if (index == 3) {
                                return (
                                    <>
                                        <div className="heading mb-5">
                                            <h2 className="title text-center mb-3">
                                                <span className="text-uppercase me-3 title1">WHAT'S</span>
                                                <span className="text-uppercase title2">NEWS</span>
                                            </h2>
                                        </div>
                                        <div className="col-md-4 col-sm-12 p-0 mx-2 mb-4" style={{ height: "400px", width: "400px" }}>
                                            <div className="card w-100 h-100 rounded-0">
                                                <Link className="w-100 h-100" to={product.href} style={{ "overflow": "hidden" }}>
                                                    <img className="card-img w-100 h-100 object-fit-cover rounded-0" alt="Cards" src={product.image} />
                                                    <div className="card-img-overlay w-100 h-100 d-flex flex-column justify-content-center text-center card-overlay text-uppercase rounded-0">
                                                        <h5 className="card-title products-card-title">{product.title}</h5>
                                                        <p className="card-text products-card-subtitle text-white">{product.text}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </>


                                );
                            }
                            return (
                                <div key={product.id} className="col-md-4 col-sm-12 p-0 mx-2 mb-4" style={{ height: "400px", width: "400px" }}>
                                    <div className="card w-100 h-100 rounded-0">
                                        <Link className="w-100 h-100" to={product.href} style={{ "overflow": "hidden" }}>
                                            <img className="card-img w-100 h-100 object-fit-cover rounded-0" alt="Cards" src={product.image} />
                                            <div className="card-img-overlay w-100 h-100 d-flex flex-column justify-content-center text-center card-overlay text-uppercase rounded-0">
                                                <h5 className="card-title products-card-title">{product.title}</h5>
                                                <p className="card-text products-card-subtitle text-white">{product.text}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            );
                        })}
                    </div>

                    <Button bClassName="mt-4" text="Load More" />

                </div>
            </div>


        </div>

    );
}

export default NewsSection;