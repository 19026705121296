import MainSection from './MainSection/MainSection';
import SliderSection from './SliderSection/SliderSection';
import NewsSection from './NewsSection/NewsSection';
import { useEffect } from 'react';


function Home() {

    useEffect(() => {
        window.scrollTo({top:0, behavior: "instant"});
    }, []);

    return (
        <>
            <MainSection />
            <SliderSection />
            <NewsSection />
        </>


    );
}

export default Home;