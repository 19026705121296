import ProductsSection from "./ProductsSection/ProductsSection";
import { useEffect } from "react";
import SideBar from "./SideBar/SideBar";

function Shop() {

    useEffect(() => {
        window.scrollTo({top:0, behavior: "instant"});
    }, []);
    return (
        <>
            <div className="container-fluid px-0 d-flex">
                <ProductsSection />
            </div>
        </>

    );
}

export default Shop;