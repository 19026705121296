import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.webp';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { RiMenu4Fill } from 'react-icons/ri';
import { NavBar } from '../NavBar/NavBar';
import { Basket } from '../Basket/Basket';
import useScrollDirection from "../../hooks/useScrollDirection";
import "./header.scss";

function Header() {

    const scrollDirection = useScrollDirection();
    const [menuActive, setMenuActive] = useState(false);
    const [basketActive, setBasketActive] = useState(false);
    const openMenu = () => setMenuActive(true);
    const closeMenu = () => setMenuActive(false);
    const openBasket = () => setBasketActive(true);
    const closeBasket = () => setBasketActive(false);

    return (
        <>
            <header className={scrollDirection === "down" ? "header w-100 position-sticky d-flex align-items-center down" : "header w-100 position-sticky d-flex align-items-center"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-6">
                            <a
                                href="/"
                                className="my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
                            >
                                <img src={logo} alt="Armenian Sound. logo" />
                            </a>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-nowrap col-lg-8 col-6">

                            <button className="btn text-white mx-2 border-0 bg-transparent position-relative" onClick={openBasket}>
                                <MdOutlineShoppingCart size={30} />
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    99+
                                </span>
                            </button>

                            <button className="btn bg-transparent text-white border-0" onClick={openMenu}>
                                <RiMenu4Fill size={35} />

                            </button>

                        </div>
                    </div>

                </div>
            </header>

            <NavBar closeMenu={closeMenu} menuActive={menuActive} />

            <Basket closeBasket={closeBasket} basketActive={basketActive} />

        </>

    );
}

export default Header;