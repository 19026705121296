import React from "react";
import { Offcanvas } from "react-bootstrap";


export const Basket = ({ basketActive, closeBasket }) => {
    return (
        <Offcanvas placement="end" show={basketActive} onHide={closeBasket} scroll={true}>
            <div className="offcanvas-header d-flex justify-content-end">
                <button type="button" className="btn-close" onClick={closeBasket} aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul>
                    <li>Item 1</li>
                    <li>Item 2</li>
                    <li>Item 3</li>
                </ul>
            </div>
            <div className="d-flex w-100 justify-content-end p-3">
                <button className="btn btn-secondary mx-2" onClick={closeBasket}>Close</button>
                <button className="btn btn-primary mx-2">Checkout</button>
            </div>

        </Offcanvas>
    )
}