import './mainSection.scss';
import videoBg from '../../../assets/videos/videoBg.mp4';
import videoBg1 from "../../../assets/videos/sound demo.mp4";
import logoBbc from '../../../assets/images/clients/logo-netflix_green.png.webp';
import logoDisney from '../../../assets/images/clients/logo-bbc_green.png.webp';
import logoNetflix from '../../../assets/images/clients/logo-sony_green.png.webp';
import logoSony from '../../../assets/images/clients/logo-disney_green.png.webp';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import Button from "../../../components/Button/Button";

function MainSection() {
    const [videoPlayer, setVideoPlayer] = useState(false);
    const logos = [
        { id: 1, logo: logoNetflix },
        { id: 2, logo: logoBbc },
        { id: 3, logo: logoSony },
        { id: 4, logo: logoDisney }

    ];
    
    const playerShow = () => setVideoPlayer(true);
    const playerClose = () => setVideoPlayer(false);

    return (
        <>
            <section className="main-section w-100 position-relative">
                <div className="overlay top-0 position-absolute w-100 d-flex justify-content-center align-items-center">
                    <div className="container px-4 text-center pt-3 mt-3">
                        <h1 className="display-3 fw-normal mb-2">Armenian Sound</h1>
                        <p className="lead text-white mb-5">AMBIENCE FROM ARMENIA</p>

                        <Button onClick={playerShow} bClassName="mb-5" text="Play Trailer" />


                        <div className="clients_section w-100">
                            <span className="w-100">CLIENTS WE'VE WORKED WITH</span>
                            <div className="row w-100 m-0 px-3 flex-nowrap justify-content-center">

                                {logos.map((item) =>
                                    <div key={item.id} className="col p-2">
                                        <img src={item.logo} alt="clients logos" className="w-100 h-100" />
                                    </div>

                                )}

                            </div>
                        </div>

                    </div>
                </div>
                <video autoPlay loop muted src={videoBg} className="w-100 h-100" />

            </section>


            <Modal className="m-0" show={videoPlayer} onHide={playerClose} size="xl" centered>
                <div className="modal-header bg-black justify-content-end p-1 border-0">
                    <button className="btn text-white bg-transparent border-0" onClick={playerClose}>
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>

                <video src={videoBg} autoPlay controls className="w-100 h-100" />

            </Modal>
        </>

    );
}

export default MainSection;