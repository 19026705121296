import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { Bars } from "react-loader-spinner";
import "./WaveformPlaylist.scss";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import React from "react";

function WaveFormPlaylist({ tracks }) {
    const [activeTrackIndex, setActiveTrackIndex] = useState(0);
    const [loadingCount, setLoadingCount] = useState(tracks.length);
    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const waveformRefs = useRef([]);

    useEffect(() => {
        // create a new instance of WaveSurfer for each track in the playlist
        waveformRefs.current = tracks.map((track) => {
            const waveform = WaveSurfer.create({
                container: document.querySelector(`#waveform-${track.id}`),

                waveColor: "#C4C4C4",
                progressColor: "#30C049",
                cursorColor: "transparent",
                barWidth: 0,
                barRadius: 0,
                barGap: 1,
                height: 85,

                backend: "WebAudio",
                responsive: true,
                partialRender: true,
                hideScrollbar: true,
            });

            waveform.load(track.url);

            waveform.on("ready", () => {
                setLoadingCount(count => count - 1);
            });

            waveform.on("finish", () => {
                handlePause();
                waveform.seekTo(0);
            });

            return waveform;

        });

        return () => {
            // clean up the WaveSurfer instances when the component unmounts
            waveformRefs.current.forEach((waveform) => {
                waveform.destroy();
            });
        };
    }, [tracks]);

    useEffect(() => {
        // check if wavesurfers are loaded
        if (loadingCount === 0) {
            setIsLoading(false);
        }
    }, [loadingCount]);

    const handlePlay = (index) => {

        if (activeTrackIndex === index) {
            waveformRefs.current[index].play();
            setIsPlaying(true);
        } else {
            waveformRefs.current[activeTrackIndex].seekTo(0);
            waveformRefs.current[activeTrackIndex].pause();
            setActiveTrackIndex(index);
            setIsPlaying(true);
            waveformRefs.current[index].play();
        }

    };

    const handlePause = () => {
        waveformRefs.current[activeTrackIndex].pause();
        setIsPlaying(false);
    };

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
                    <Bars />
                </div>
            )}
            <div className={`waveform-playlist d-flex flex-column gap-2 p-2 ${isLoading === true ? "invisible" : ""} `}>
                {tracks.map((track, index) => (
                    <div
                        key={track.id}
                        id={`track-${index}`}
                        className="waveform-playlist-track 
                        d-flex align-items-center 
                        gap-2 p-1 border flex-wrap"
                    >
                        <div className="waveform-playlist-buttons">
                            {isPlaying && activeTrackIndex === index ? (
                                <button
                                    className="waveform-playlist-button 
                                    d-flex justify-content-center align-items-center 
                                    bg-transparent border-0 text-white"
                                    onClick={handlePause}
                                >
                                    <BsFillPauseCircleFill size={45} />
                                </button>
                            ) : (
                                <button
                                    className="waveform-playlist-button
                                    d-flex justify-content-center align-items-center 
                                    border-0 bg-transparent text-white"
                                    onClick={() => handlePlay(index)}
                                >
                                    <BsFillPlayCircleFill size={45} />
                                </button>
                            )}
                        </div>
                        <div className="waveform-playlist-waveform" id={`waveform-${track.id}`} />
                        <h3 className="waveform-playlist-title mx-2">{track.title}</h3>
                    </div>
                ))}
                <div className="w-100">
                    <div className="btn-group my-4" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary mx-1">1</button>
                        <button type="button" className="btn btn-outline-secondary mx-1">2</button>
                        <button type="button" className="btn btn-outline-secondary mx-1">3</button>
                        <button type="button" className="btn btn-outline-secondary mx-1">4</button>
                    </div>
                </div>
            </div>
        </>
    );

};

export default WaveFormPlaylist;