import './footer.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.webp';
import { BsFacebook, BsTwitter, BsInstagram, BsYoutube, BsVimeo } from 'react-icons/bs';

function Footer() {
    const utils = [
        { id: 1, href: "/collections", title: "COLLECTION" },
        { id: 2, href: "/news", title: "NEWS" },
        { id: 3, href: "/about", title: "ABOUT" },
        { id: 4, href: "/contact", title: "CONTACT" },
        { id: 5, href: "/terms-conditions", title: "TERMS & CONDITIONS" },

    ];
    const [iconClassName, iconSize] = ["social-icon", 25];

    const facebookIcon = <BsFacebook size={iconSize} className={iconClassName} />;
    const twitterIcon = <BsTwitter size={iconSize} className={iconClassName} />;
    const instagramIcon = <BsInstagram size={iconSize} className={iconClassName} />;
    const youtubeIcon = <BsYoutube size={iconSize} className={iconClassName} />;
    const vimeoIcon = <BsVimeo size={iconSize} className={iconClassName} />;

    const menuIcons = [
        { id: 1, icon: facebookIcon, href: "https://www.facebook.com/" },
        { id: 2, icon: twitterIcon, href: "https://twitter.com/" },
        { id: 3, icon: instagramIcon, href: "https://www.instagram.com/" },
        { id: 4, icon: youtubeIcon, href: "https://www.youtube.com/" },
        { id: 5, icon: vimeoIcon, href: "https://vimeo.com/" }

    ];

    return (
        <div className="container-fluid p-0">
            <footer className="pt-3 mt-3 text-center d-flex flex-column">
                <h3 className="footer-heading text-center text-uppercase mt-3">Stay In Touch</h3>
                <div className="social-icons mb-2 d-flex justify-content-center w-100 mt-4">
                    <ul className="nav col-5 justify-content-center list-unstyled flex-nowrap d-flex mb-4">
                        {menuIcons.map((icon) =>
                            <li key={icon.id} className="mx-3 px-2">
                                <a href={icon.href} className="text-muted">{icon.icon}</a>
                            </li>
                        )}

                    </ul>
                </div>
                <ul className="footer-nav nav justify-content-center align-items-center py-3 mb-3">
                    {utils.map((item) => {
                        return (
                            <li key={item.id} className="footer-nav-item nav-item">
                                <Link className="nav-link px-5 text-muted" to={item.href}>{item.title}</Link>
                            </li>
                        );

                    })}

                </ul>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center py-4 mt-4">

                    <a
                        href="/"
                        className="text-white text-decoration-none mb-3 px-5"
                    >
                        <img src={logo} alt="Armenian Sound. logo" />
                    </a>
                    <p className="mb-3 px-5">2022 © ARMENIANSOUND.AM</p>
                </div>


            </footer>
        </div>


    );
}

export default Footer;