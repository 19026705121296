import { useState } from "react";
import WaveFormPlaylist from "../WaveFormPlaylist/WaveFormPlaylist";
import { BsFilterSquareFill } from "react-icons/bs";
import SideBar from "../SideBar/SideBar";

const tracks = [
    {
        id: 0,
        title: "Brahms: St Anthony Chorale - Theme, Two Pianos Op.56b",
        url: "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
    },
    {
        id: 1,
        title: "Franz Schubert's Ständchen - Voice (Clarinet) & Piano",
        url: "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
    },
    {
        id: 3,
        title: "Por Una Cabeza: Tango Song by Gardel and Le Pera",
        url: "https://www.mfiles.co.uk/mp3-downloads/por-una-cabeza.mp3"
    },
    {
        id: 4,
        title: "Cherry Ripe - Traditional Song",
        url: "https://www.mfiles.co.uk/mp3-downloads/cherry-ripe.mp3"
    },
    {
        id: 5,
        title: "The Origins of Remembrance Day",
        url: "https://www.mfiles.co.uk/mp3-downloads/the-last-post.mp3"
    },
    {
        id: 6,
        title: "Sofeggietto - one of his best-known works",
        url: "https://www.mfiles.co.uk/mp3-downloads/cpe-bach-solfeggietto.mp3"
    },
    {
        id: 7,
        title: "Album Leaf (piano) Op.19 No.3",
        url: "https://www.mfiles.co.uk/mp3-downloads/Tchaikovsky-op19-no3-fueillet-d-album.mp3"
    },
    {
        id: 8,
        title: "Saltarello 2 - original melody on oboe",
        url: "https://www.mfiles.co.uk/mp3-downloads/saltarello2.mp3"
    },
    {
        id: 9,
        title: "Flowers of the Forest - original version",
        url: "https://www.mfiles.co.uk/mp3-downloads/flowers-of-the-forest-original.mp3"
    },
    {
        id: 10,
        title: "Brahms' Symphony 3 theme - Piano Solo",
        url: "https://www.mfiles.co.uk/mp3-downloads/brahms-symphony3-3-theme-piano-solo.mp3"
    },


];

export default function ProductsSection() {

    const [sideBar, setSideBar] = useState(false);

    const sidebarOpen = () => {
        setSideBar(true);
    };

    const sidebarClose = () => {
        setSideBar(false);
    };

    return (

        <>
            <div className="container-fluid text-center p-0">
                <div className="container">
                    <div className="products-header d-flex justify-content-between align-items-center">

                        <button onClick={sidebarOpen} className="bg-transparent border-0 text-white my-3">
                            <BsFilterSquareFill size={35} />
                        </button>

                        <input className="form-control mx-5" placeholder="Type to search..." />

                        <select class="form-select" aria-label="Default select example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>

                <div className="container">
                    <WaveFormPlaylist tracks={tracks} />
                </div>
            </div>


            <SideBar sideBar={sideBar} sidebarClose={sidebarClose} />
        </>

    )
}
