import React from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsTwitter, BsInstagram, BsYoutube, BsVimeo } from 'react-icons/bs';
import { SlClose } from 'react-icons/sl';
import logo from '../../assets/images/logo.webp';
import { Modal } from "react-bootstrap";
import "./navBar.scss";

export const NavBar = ({ closeMenu, menuActive }) => {

    const [iconClassName, iconSize] = ["social-icon", 35];

    const facebookIcon = <BsFacebook size={iconSize} className={iconClassName} />;
    const twitterIcon = <BsTwitter size={iconSize} className={iconClassName} />;
    const instagramIcon = <BsInstagram size={iconSize} className={iconClassName} />;
    const youtubeIcon = <BsYoutube size={iconSize} className={iconClassName} />;
    const vimeoIcon = <BsVimeo size={iconSize} className={iconClassName} />;

    const menuIcons = [
        { id: 1, icon: facebookIcon, href: "https://www.facebook.com/" },
        { id: 2, icon: twitterIcon, href: "https://twitter.com/" },
        { id: 3, icon: instagramIcon, href: "https://www.instagram.com/" },
        { id: 4, icon: youtubeIcon, href: "https://www.youtube.com/" },
        { id: 5, icon: vimeoIcon, href: "https://vimeo.com/" }

    ];

    const menuItems = [
        { id: 1, title: "Shop", href: "/shop" },
        { id: 2, title: "Individual", href: "/individual" },
        { id: 3, title: "License", href: "/license" },
        { id: 4, title: "Gallery", href: "/gallery" },
        { id: 5, title: "About", href: "/about" },
        { id: 6, title: "News", href: "/news" },
        { id: 7, title: "Contact", href: "/contact" },
        { id: 8, title: "Login/Register", href: "/login-register" },
        { id: 9, title: "Whishlist", href: "/whishlist" }
    ];


    return (
        <Modal className="w-100 h-100 border-0" show={menuActive} onHide={closeMenu} animation={false} fullscreen={true}>
            <div className="modal-header bg-black border-0">
                <a href="/" onClick={closeMenu}>
                    <img src={logo} alt="Armenian Sound. logo" />
                </a>
                <button className="btn bg-transparent text-white border-0" onClick={closeMenu}>
                    <SlClose size={40} />
                </button>
            </div>
            <div className="modal-body bg-black">
                <div className="container">
                    <ul className="p-0 w-100 d-flex justify-content-center align-items-center flex-column">
                        {menuItems.map((item) =>
                            <li key={item.id} className="categories-item text-white list-group-item text-uppercase py-3">
                                <Link onClick={closeMenu} to={item.href}>{item.title}</Link>
                            </li>
                        )}

                    </ul>

                    <div className="social-icons d-flex justify-content-center w-100 mt-4">
                        <ul className="nav col-5 justify-content-center list-unstyled flex-nowrap d-flex">
                            {menuIcons.map((icon) =>
                                <li key={icon.id} className="mx-2 px-2">
                                    <a href={icon.href} className="text-muted">{icon.icon}</a>
                                </li>
                            )}

                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    )
}